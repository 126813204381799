<template>
  <div class="task-add">
    <!-- <div class="main-Title bgff"><h2>Add OF</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover">
        <el-form ref="postData" :model="postData" :rules="rules" label-width="110px">
          <el-form-item label="OF名称" prop="emuName"
            ><el-input v-model="postData.emuName" placeholder="请输入OF名称"
          /></el-form-item>
          <el-form-item label="OF networkAff" prop="networkAff"
            ><el-input v-model="postData.networkAff" placeholder="请输入OF networkAff"
          /></el-form-item>
          <el-form-item label="PB链接" prop="postbackUrl"
            ><el-input v-model="postData.postbackUrl" placeholder="请输入PB链接"
          /></el-form-item>
          <el-form-item align="center" class="mb0">
            <el-button :loading="loading.btn" size="small" type="success" @click="add('postData')"
              >保存</el-button
            >
            <el-button :loading="loading.btn" size="small" type="primary" @click="goOff()"
              >取消</el-button
            >
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
  import { handleEMUAdd } from '@/api/DDJ/v3/emu.js';
  export default {
    data() {
      return {
        postData: {},
        loading: {
          btn: false,
        },
        rules: {
          emuName: [
            {
              required: true,
              message: '请输入OF名称',
              trigger: 'blur',
            },
          ],
        },
      };
    },
    created() {},
    methods: {
      // 保存
      add(formName) {
        this.$refs[formName].validate((valid) => {
          if (!valid) return;
          this.loading.btn = true;
          handleEMUAdd(this.postData)
            .then((res) => {
              console.log(res);
              this.loading.btn = false;
              this.$message({
                message: '添加成功',
                type: 'success',
              });
              this.$router.push({
                path: '/click/emu/v3',
              });
            })
            .catch((e) => {
              this.$message.error(e);
              this.loading.btn = false;
            });
        });
      },
      goOff() {
        this.$router.push({
          path: '/click/emu/v3',
        });
      },
    },
  };
</script>

<style></style>
